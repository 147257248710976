import { formatAmount } from "./numbers";

export const shortenAddress = (address?: `0x${string}`, length: number = 9) => {
  if (!length) {
    return "";
  }
  if (!address) {
    return address;
  }
  if (address.length < 10) {
    return address;
  }
  let left = Math.floor((length - 3) / 2) + 1;
  return (
    address.substring(0, left) +
    "..." +
    address.substring(address.length - (length - (left + 1)), address.length)
  );
};

export const copyToClipboard = (value: string) => {
  navigator.clipboard.writeText(value);
};

export const getUSDPrice = (num, tokenPrice) => {
  if (!num || !tokenPrice) return 0;
  return +((formatAmount(num, 18, 6, 0, true) as number) * tokenPrice).toFixed(
    2
  );
};

export const hexToString = (hex) => {
  // 去掉前缀
  hex = hex.slice(2);
  let str = "";

  for (let i = 0; i < hex.length; i += 2) {
    // 将每两个十六进制字符转换为字符
    const charCode = parseInt(hex.substr(i, 2), 16);
    if (charCode !== 0) {
      // 忽略后面的零
      str += String.fromCharCode(charCode);
    }
  }

  return str;
};

export const setParamToUrl = (paramList: { key: string; val: string }[]) => {
  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  const params = new URLSearchParams(url.search);

  // 添加参数
  paramList.forEach(({ key, val }) => {
    params.set(key, val);
  });

  url.search = params.toString();

  return url.toString();
};

export const getCurLocalWinnerList = () => {
  let curList = [] as string[];
  try {
    const data = localStorage.getItem("WINNER_LIST");
    if (data) {
      curList = JSON.parse(data);
    }
  } catch (err) {}

  return curList;
};
