import BigNumber from "bignumber.js";
import { formatUnits, parseUnits } from "viem";

export type BigNumberish = number | string | BigNumber | bigint;

export const convertBigNumberishToBigInt = (num: BigNumberish) => {
  if (typeof num === "number") return BigInt(Math.trunc(num));
  if (BigNumber.isBigNumber(num)) return BigInt(num.toFixed(0, 5));
  return BigInt(num);
};

export const limitDecimals = (amount: BigNumberish, maxDecimals?: number) => {
  let amountStr = amount.toString();
  if (maxDecimals === undefined) {
    return amountStr;
  }
  if (maxDecimals === 0) {
    return amountStr.split(".")[0];
  }
  const dotIndex = amountStr.indexOf(".");
  if (dotIndex !== -1) {
    let decimals = amountStr.length - dotIndex - 1;
    if (decimals > maxDecimals) {
      amountStr = amountStr.substring(
        0,
        amountStr.length - (decimals - maxDecimals)
      );
    }
  }
  return amountStr;
};

export const padDecimals = (amount: BigNumberish, minDecimals: number) => {
  let amountStr = amount.toString();
  const dotIndex = amountStr.indexOf(".");
  if (dotIndex !== -1) {
    const decimals = amountStr.length - dotIndex - 1;
    if (decimals < minDecimals) {
      amountStr = amountStr.padEnd(
        amountStr.length + (minDecimals - decimals),
        "0"
      );
    }
  } else {
    amountStr = amountStr + "." + "0".repeat(minDecimals);
  }
  return amountStr;
};

export const formatAmount = (
  amount: BigNumberish | undefined,
  tokenDecimals: number,
  displayDecimals?: number,
  defaultValue?: string | number,
  isNum: boolean = false
) => {
  if (!defaultValue) {
    defaultValue = "...";
  }
  if (amount === undefined || !amount?.toString()?.length) {
    return defaultValue;
  }
  if (displayDecimals === undefined) {
    displayDecimals = 4;
  }
  let amountStr = formatUnits(
    convertBigNumberishToBigInt(amount),
    tokenDecimals
  );

  amountStr = limitDecimals(amountStr, displayDecimals);
  if (displayDecimals !== 0) {
    amountStr = padDecimals(amountStr, displayDecimals);
  }
  if (isNum) {
    return Number(amountStr);
  }
  return amountStr;
};

export function bigNumberify(n: BigNumberish) {
  try {
    if (typeof n === "bigint") return new BigNumber(n.toString());
    return new BigNumber(n);
  } catch (e) {
    console.error("bigNumberify error", e);
    return undefined;
  }
}

export const parseValue = (
  value: string,
  tokenDecimals: number,
  defaultValue = undefined
) => {
  const fValue = +value;
  if (
    !Number.isFinite(fValue) ||
    `${fValue}`.includes("e") ||
    `${fValue}`.includes("E")
  ) {
    return defaultValue;
  }
  const pValue = parseFloat(value);
  if (isNaN(pValue)) {
    return defaultValue;
  }
  value = limitDecimals(value, tokenDecimals);
  const amount = parseUnits(value, tokenDecimals);

  return bigNumberify(amount.toString());
};
