import snakeIcon from "assets/snake.png";
import bearIcon from "assets/bear.png";
import whaleIcon from "assets/whale.png";
import bullIcon from "assets/bull.png";

export enum TEAMS {
  whale = 0,
  bear,
  snake,
  bull,
}

export const TEAM_INFO = [
  {
    name: "Whale",
    desc: "Greed that devours others",
    contentText:
      "Most of the funds you spend on Keys are allocated to the jackpot, with a smaller portion going to Key holders and $FOMO stakers. At the end of each round, a larger portion of the winnings is reserved for the next round, while stakers and holders receive fewer dividends. Whales prioritize retaining more MEME tokens in the jackpot for future rounds, rather than distributing them as dividends.",
    icon: whaleIcon,
    id: TEAMS.whale,
    potPercent: 0.5,
  },
  {
    name: "Bear",
    desc: "Lone Wolf",
    contentText:
      "Choosing the Bears means crowding out $FOMO stakers. None of your Key purchase funds will go to $FOMO stakers—instead, most of it benefits Key holders. At the end of the round, all winnings are paid out as dividends to Key holders, leaving $FOMO stakers with nothing.",
    icon: bearIcon,
    id: TEAMS.bear,
    potPercent: 0.43,
  },
  {
    name: "Snake",
    desc: "Trickle down dividend",
    contentText:
      "Most of the funds you spend on Keys go to $FOMO stakers, with a smaller portion going to Key holders. At the end of each round, a larger share of the winnings is distributed as rewards to $FOMO stakers. The majority of what Snake team members invest flows into dividends, other players, and $FOMO stakers, while a smaller portion goes into the jackpot.",
    icon: snakeIcon,
    id: TEAMS.snake,
    potPercent: 0.2,
  },

  {
    name: "Bull",
    desc: "Sprint",
    contentText:
      "At the end of the round, most winnings are distributed as dividends to Key holders. Bulls allow other players to receive a larger share, while $FOMO stakers receive less compared to the Snake team’s rules.",
    icon: bullIcon,
    id: TEAMS.bull,
    potPercent: 0.35,
  },
];

export const getTeamInfo = (id) => {
  return TEAM_INFO.find((item) => item.id === id);
};
