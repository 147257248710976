import { useBreakpoint } from "@chakra-ui/react";

const MOBILE_BREAKPOINTS = new Set(["base", "sm"]);

export function useIsMobileView() {
  const breakpoint = useBreakpoint("") as string;
  return MOBILE_BREAKPOINTS.has(breakpoint);
}

export function useIsPadView() {
  const breakpoint = useBreakpoint("") as string;
  return new Set(["md"]).has(breakpoint);
}
