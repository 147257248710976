import {
  Box,
  Button,
  Center,
  Flex,
  Icon,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import CustomModal from "components/CustomModal";
import winImg from "assets/win.png";
import { getUSDPrice, shortenAddress } from "utils/util";
import { getTeamInfo } from "config/team";
import { formatAmount } from "utils/numbers";
import { useEffect } from "react";

const WinModal = ({
  isOpen,
  onClose,
  address,
  curRoundNum,
  winnerInfo,
  tokenPrice,
  vaultInfo,
  keysOwned,
  onWithdraw,
  memeTokenInfo,
}) => {
  useEffect(() => {
    if (isOpen) {
      localStorage.setItem(
        "FOMOME_ROUND_INFO_SHOWN",
        JSON.stringify({ curRoundNum: curRoundNum - 1 })
      );
    }
  }, [isOpen]);

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      title={`End of Round #${curRoundNum - 1}`}
    >
      <VStack spacing={4} color="white" fontSize={{ lg: "md", base: "xs" }}>
        <Image src={winImg} w={{ lg: "36.5rem", base: "20.625rem" }} />
        <VStack spacing={3} w="100%">
          <Flex w="100%">
            <Text w="20%" textAlign={"left"}>
              Winner
            </Text>
            <Text w="40%" textAlign={"right"}>
              {shortenAddress(winnerInfo.address as `0x${string}`)}
            </Text>
            <Flex gap={1} w="40%" justifyContent={"flex-end"}>
              <Image
                src={getTeamInfo(winnerInfo.teamId)?.icon}
                boxSize={{ lg: 6, base: 4 }}
              />
              <Text>{getTeamInfo(winnerInfo.teamId)?.name} Team</Text>
            </Flex>
          </Flex>
          <Flex w="100%">
            <Text w="20%" textAlign={"left"}>
              Winners Get
            </Text>
            <Text w="40%" textAlign={"right"}>
              {+formatAmount(winnerInfo.winningNum, 18, 2)}{" "}
              {memeTokenInfo?.symbol}
            </Text>
            <Text w="40%" textAlign={"right"}>
              {(
                (formatAmount(
                  winnerInfo.winningNum,
                  18,
                  2,
                  0,
                  true
                ) as number) * (tokenPrice || 0)
              ).toFixed(2)}{" "}
              USD
            </Text>
          </Flex>
        </VStack>

        {!!address && !!vaultInfo && (
          <VStack spacing={3} p={{ lg: 6, base: 2 }} bg="b02" w="100%">
            <Center w="100%" h="26px" bg="lightYellow">
              <Text
                color="brand"
                fontWeight={"bold"}
                fontSize={{ lg: "xl", base: "md" }}
              >
                You
              </Text>
            </Center>
            <Flex w="100%">
              <Text w="20%" textAlign={"left"}>
                key
              </Text>
              <Text w="80%" textAlign={"right"}>
                {+formatAmount(keysOwned, 18, 4)}
              </Text>
            </Flex>
            <Flex w="100%">
              <Text w="20%" textAlign={"left"}>
                Dividends
              </Text>
              <Text w="40%" textAlign={"right"}>
                {+formatAmount(vaultInfo.dividends, 18, 4)}{" "}
                {memeTokenInfo?.symbol}
              </Text>
              <Text w="40%" textAlign={"right"}>
                {getUSDPrice(vaultInfo.dividends, tokenPrice)} USD
              </Text>
            </Flex>
            <Flex w="100%">
              <Text w="20%" textAlign={"left"}>
                Referrals
              </Text>
              <Text w="40%" textAlign={"right"}>
                {+formatAmount(vaultInfo.referrals, 18, 4)}{" "}
                {memeTokenInfo?.symbol}
              </Text>
              <Text w="40%" textAlign={"right"}>
                {getUSDPrice(vaultInfo.referrals, tokenPrice)} USD
              </Text>
            </Flex>
            <Flex w="100%">
              <Text w="20%" textAlign={"left"}>
                Rewards
              </Text>
              <Text w="40%" textAlign={"right"}>
                {formatAmount(vaultInfo.atExit, 18, 4)} {memeTokenInfo?.symbol}
              </Text>
              <Text w="40%" textAlign={"right"}>
                {getUSDPrice(vaultInfo.atExit, tokenPrice)} USD
              </Text>
            </Flex>
            <Box bg="b03" w="100%" h="1px"></Box>
            <Flex
              w="100%"
              fontSize={{ lg: "lg", base: "xs" }}
              fontWeight={"semibold"}
            >
              <Text w="20%" textAlign={"left"}>
                Total
              </Text>
              <Text w="40%" textAlign={"right"}>
                {+formatAmount(vaultInfo.total, 18, 4)} {memeTokenInfo?.symbol}
              </Text>
              <Text w="40%" textAlign={"right"}>
                {getUSDPrice(vaultInfo.total, tokenPrice)} USD
              </Text>
            </Flex>
            <Button variant={"brand"} w="100%" onClick={onWithdraw}>
              <Text>Withdraw Dividends</Text>
              <Icon
                width={{ lg: "25px", base: "20px" }}
                height={{ lg: "25px", base: "20px" }}
                viewBox="0 0 25 24"
                fill="none"
              >
                <g clipPath="url(#clip0_10122_2881)">
                  <mask
                    id="mask0_10122_2881"
                    maskUnits="userSpaceOnUse"
                    x="2"
                    y="2"
                    width="25"
                    height="24"
                  >
                    <rect x="2.5" y="2" width="24" height="24" fill="#D9D9D9" />
                  </mask>
                  <g mask="url(#mask0_10122_2881)">
                    <path
                      d="M6.75469 22.2863C5.99754 22.2863 5.35826 22.0256 4.83683 21.5041C4.3154 20.9827 4.05469 20.3434 4.05469 19.5863V11.6148C4.05469 10.8577 4.3154 10.2184 4.83683 9.69699C5.35826 9.17556 5.99754 8.91484 6.75469 8.91484H8.57612V11.6148H6.75469V19.5863H18.2404V11.6148H16.419V8.91484H18.2404C18.9975 8.91484 19.6368 9.17556 20.1583 9.69699C20.6797 10.2184 20.9404 10.8577 20.9404 11.6148V19.5863C20.9404 20.3434 20.6797 20.9827 20.1583 21.5041C19.6368 22.0256 18.9975 22.2863 18.2404 22.2863H6.75469ZM11.1475 16.1791V6.8577L9.77612 8.22913L7.8904 6.34342L12.4975 1.71484L17.1047 6.34342L15.219 8.22913L13.8475 6.8577V16.1791H11.1475Z"
                      fill="black"
                    />
                  </g>
                </g>
                <defs>
                  <clipPath id="clip0_10122_2881">
                    <rect
                      width="24"
                      height="24"
                      fill="white"
                      transform="translate(0.5)"
                    />
                  </clipPath>
                </defs>
              </Icon>
            </Button>
          </VStack>
        )}
      </VStack>
    </CustomModal>
  );
};

export default WinModal;
