import "./App.css";
import { Center, Flex } from "@chakra-ui/react";
import Header from "./Header";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import GamePage from "pages/GamePage";
import { PAGE_PATH } from "config";
import PageNotFound from "pages/PageNotFound";
import TopScrollBanner from "./TopScrollBanner";
import netImg from "assets/net.svg";
import HomePage from "pages/HomePage";
import EarnPage from "pages/EarnPage";

const AppLayout = ({ Outlet }: { Outlet: JSX.Element }) => {
  return (
    <Flex
      w="100vw"
      minH="100vh"
      backgroundBlendMode={"screen, normal"}
      flexDirection={"column"}
      pos={"relative"}
      overflowX={"hidden"}
      id="PageContainer"
      bgImage={netImg}
      bgSize={"auto"}
      bgRepeat={"repeat"}
    >
      <TopScrollBanner />
      <Header />
      {Outlet}
    </Flex>
  );
};

const router = createBrowserRouter([
  {
    path: PAGE_PATH.homePage,
    element: <AppLayout Outlet={<HomePage />} />,
  },
  {
    path: PAGE_PATH.game,
    element: <AppLayout Outlet={<GamePage />} />,
  },
  {
    path: PAGE_PATH.earn,
    element: <AppLayout Outlet={<EarnPage />} />,
  },
  {
    path: "*",
    element: <AppLayout Outlet={<PageNotFound />} />,
  },
]);

function App() {
  return (
    <RouterProvider
      router={router}
      fallbackElement={<Center>Loading...</Center>}
    />
  );
}

export default App;
