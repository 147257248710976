import { Flex, Image, Text, VStack } from "@chakra-ui/react";
import CustomModal from "components/CustomModal";
import { TEAM_INFO } from "config/team";

const TeamModal = ({ isOpen, onClose }) => {
  return (
    <CustomModal isOpen={isOpen} onClose={onClose} title={"CHOOSE A TEAM"}>
      <Text color="white" fontSize={"xs"}>
        With every Key purchase, you’ll pick one of four teams. The team you
        choose shapes how your Key funds and the jackpot will be distributed.
        Each team has its own strategy—here’s how it works for each one:
      </Text>
      <VStack mt={6} spacing={5}>
        {TEAM_INFO.map(({ icon, name, desc, contentText, id }) => {
          return (
            <Flex key={id} gap={4} color="white">
              <Image src={icon} alt={name} boxSize={8} />
              <VStack spacing={2} alignItems={"flex-start"}>
                <Flex alignItems={"center"}>
                  <Text fontSize={"md"} fontWeight={"bold"}>
                    {name} -
                  </Text>
                  {"  "}
                  <Text fontSize={"sm"}>"{desc}".</Text>
                </Flex>
                <Text color="b07" fontSize={"xs"}>
                  {contentText}
                </Text>
              </VStack>
            </Flex>
          );
        })}
      </VStack>
    </CustomModal>
  );
};

export default TeamModal;
