export const socialMediaLinks = {
  why: {
    twitter: "https://x.com/whyanelephant",
    tg: "https://t.me/whyenelephant",
    website: "https://www.madphant.com/",
  },
  bnx: {
    website: "https://www.binaryx.pro/",
    twitter: "https://x.com/binary_x",
    tg: "https://t.me/binaryxGlobal",
  },
  twitter: "https://x.com/fomome_me",
  tg: "https://t.me/fomome_official",
};

export const subgraphUrl =
  "https://api.studio.thegraph.com/query/91634/skyler-fomome-bsctestnet/version/latest";
