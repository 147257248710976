import { Box, Center, Flex, Icon, Image, Text } from "@chakra-ui/react";
import ConnectWalletButton from "components/ConnectWalletButton";
import { borders } from "theme";
import twitterIcon from "assets/twitter.svg";
import telegramIcon from "assets/telegram.svg";
import twitterWhiteIcon from "assets/twitter-white.svg";
import telegramwhiteIcon from "assets/telegram-white.svg";
import twitterHoverIcon from "assets/twitter-hover.svg";
import telegramHoverIcon from "assets/telegram-hover.svg";
import { useEffect, useState } from "react";
import { useAccount } from "wagmi";
import WalletDropdown from "./WalletDropdown";
import { useIsMobileView, useIsPadView } from "hooks/usePageSize";
import logo from "assets/logo.svg";
import { Link } from "react-router-dom";
import { socialMediaLinks } from "config/common";

const Header = () => {
  const { isConnected } = useAccount();
  const isMobile = useIsMobileView();
  const isPad = useIsPadView();
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);

  useEffect(() => {
    const PageContainer = document.getElementById("PageContainer");
    if (PageContainer?.style) {
      PageContainer.style.overflowY = isOpenDrawer ? "hidden" : "scroll";
    }
  }, [isOpenDrawer]);

  const isGamePage =
    window.location.pathname === "/" || window.location.pathname === "/game";
  const isEarnPage = window.location.pathname === "/earn";

  return (
    <>
      <Flex bg="brand" h={"3.75rem"} pos="relative">
        <Flex
          flex="1"
          border={borders.blackNormal}
          borderRight={"none"}
          pl={{ lg: 10, md: 6, base: 2 }}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Flex
            h="100%"
            alignItems={"center"}
            w={{ lg: "100%", md: "unset" }}
            borderRight={{ lg: "none", md: borders.blackNormal, base: "none" }}
            justifyContent={"flex-start"}
            gap={{ md: 0, base: 2 }}
          >
            {(isMobile || isPad) && (
              <Icon
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                fill="none"
                onClick={() => setIsOpenDrawer(!isOpenDrawer)}
                cursor="pointer"
                mr={{ md: 3, base: 0 }}
              >
                <mask
                  id="mask0_10122_7867"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="24"
                  height="24"
                >
                  <rect width="24" height="24" fill="#98A2B3" />
                </mask>
                <g mask="url(#mask0_10122_7867)">
                  <path
                    d="M3 19.1998V17.1998H21V19.1998H3ZM3 12.9998V10.9998H21V12.9998H3ZM3 6.7998V4.7998H21V6.7998H3Z"
                    fill="black"
                  />
                </g>
              </Icon>
            )}
            <Link
              to="/"
              style={{ height: "100%", display: "flex", alignItems: "center" }}
            >
              <Flex
                w={{ md: "215px", base: "124px" }}
                h={{ md: "100%", base: "40px" }}
              >
                <Image src={logo} w={{ md: "180px", base: "124px" }} />
              </Flex>
            </Link>
            {!isMobile && !isPad && (
              <>
                <Link to="/" style={{ height: "100%" }}>
                  <Center
                    px={4}
                    h="100%"
                    color={isGamePage ? "white" : "black"}
                    bg={isGamePage ? "black" : "transparent"}
                    _hover={{ color: "brand", bg: "black" }}
                    fontWeight={"extrabold"}
                    fontFamily={"Rubik"}
                    fontSize={"xl"}
                  >
                    Game
                  </Center>
                </Link>
                <Link to="/earn" style={{ height: "100%" }}>
                  <Center
                    px={4}
                    h="100%"
                    color={isEarnPage ? "white" : "black"}
                    bg={isEarnPage ? "black" : "transparent"}
                    _hover={{ color: "brand", bg: "black" }}
                    fontWeight={"extrabold"}
                    fontFamily={"Rubik"}
                    fontSize={"xl"}
                  >
                    Earn
                  </Center>
                </Link>
              </>
            )}
          </Flex>

          {!isMobile && <Menus isShowOnTop={isMobile} />}
        </Flex>
        <Center w={"3.75rem"} border={borders.blackNormal}>
          <Icon
            width={{ lg: "36px", base: "32px" }}
            height={{ lg: "36px", base: "32px" }}
            viewBox="0 0 36 36"
            fill="none"
          >
            <path
              d="M35.4649 22.3443C33.063 31.9813 23.3024 37.8659 13.6478 35.4626C4.01075 33.0533 -1.86447 23.2927 0.537427 13.6557C2.93932 4.01874 12.6941 -1.86594 22.3252 0.53741C31.9857 2.91131 37.8609 12.7014 35.4649 22.3443Z"
              fill="black"
            />
            <path
              d="M24.886 17.1203L24.872 14.1242H24.8829L22.338 12.6261L17.9772 15.1973L13.6427 12.6261L11.0994 14.1242V17.1203L15.4602 19.6791V24.8092L17.9927 26.2933L20.5251 24.8092V19.6791L24.886 17.1203Z"
              fill="#FCD535"
            />
            <path
              d="M17.9787 4.95117L11.0979 9.00807L13.6303 10.5061L17.9787 7.93492L22.3396 10.5061L24.872 9.00807L17.9787 4.95117Z"
              fill="#FCD535"
            />
            <path
              d="M9.28077 23.2987L9.26682 18.181L6.73593 16.6845V24.8107L13.6292 28.8537V25.8575L9.28077 23.2987Z"
              fill="#FCD535"
            />
            <path
              d="M9.26682 16.0456V13.0634L11.8117 11.5653L9.26682 10.0672L6.73438 11.5653V14.5475L9.26682 16.0456Z"
              fill="#FCD535"
            />
            <path
              d="M17.9776 10.0672L15.4452 11.5653L17.9776 13.0634L20.5225 11.5653L17.9776 10.0672Z"
              fill="#FCD535"
            />
            <path
              d="M13.6303 20.7399L11.0979 19.2418V22.2379L13.6303 23.7221V20.7399Z"
              fill="#FCD535"
            />
            <path
              d="M17.9787 28.4288L15.4463 26.9307V29.9129L17.9787 31.4109L20.5236 29.9129V26.9307L17.9787 28.4288Z"
              fill="#FCD535"
            />
            <path
              d="M26.688 10.0672L24.1555 11.5653L26.688 13.0634V16.0456L29.2328 14.5475V11.5653L26.688 10.0672Z"
              fill="#FCD535"
            />
            <path
              d="M29.2344 16.683L26.7019 18.181L26.688 23.2987L22.3411 25.856V28.8521L29.2344 24.8092V16.683Z"
              fill="#FCD535"
            />
            <path
              d="M24.8726 22.2378L22.3401 23.722V20.7398L24.8726 19.2417V22.2378Z"
              fill="#FCD535"
            />
          </Icon>
        </Center>
        {isConnected ? <WalletDropdown /> : <ConnectWalletButton />}
        {isOpenDrawer && (
          <Box
            pos="absolute"
            top="60px"
            left="0"
            h={"calc(100vh - 84px)"}
            w="100%"
            zIndex={9}
            bg="rgba(0, 0, 0, 0.80)"
            onClick={() => setIsOpenDrawer(false)}
            pointerEvents={"visible"}
          >
            <Box bg="black" w="100%" py={4}>
              <Menus color="white" />
            </Box>
          </Box>
        )}
      </Flex>
    </>
  );
};

const Menus = ({ isShowOnTop = true, ...rest }) => {
  const [isTwitterHover, setIsTwitterHover] = useState(false);
  const [isTelegramHover, setIsTelegramHover] = useState(false);
  const isMobile = useIsMobileView();
  const isPad = useIsPadView();

  const isGamePage =
    window.location.pathname === "/" || window.location.pathname === "/game";
  const isEarnPage = window.location.pathname === "/earn";
  const { twitter, tg } = socialMediaLinks;

  return (
    <Flex
      gap={0}
      alignItems={"center"}
      flexDirection={{
        lg: "row",
        md: isShowOnTop ? "column" : "row",
        base: "column",
      }}
      {...rest}
    >
      {(isMobile || isShowOnTop) && (
        <Box w="100%">
          <>
            <Link to="/">
              <Flex
                w="100%"
                bg={isGamePage ? "rgba(255, 255, 255, 0.10)" : "transparent"}
                _hover={
                  isPad
                    ? { bg: "rgba(255, 255, 255, 0.10)" }
                    : {
                        color: "brand",
                        bg: isPad ? "transparent" : "black",
                      }
                }
                cursor="pointer"
                pl={6}
              >
                <Center
                  h="48px"
                  w="100%"
                  color={isGamePage ? "brand" : "white"}
                  justifyContent={isPad || isMobile ? "flex-start" : "center"}
                  fontWeight={"extrabold"}
                  fontFamily={"Rubik"}
                  fontSize={"xl"}
                >
                  Game
                </Center>
              </Flex>
            </Link>

            <Link to="/earn">
              <Flex
                w="100%"
                bg={isEarnPage ? "black" : "transparent"}
                pl={6}
                _hover={
                  isPad
                    ? { bg: "rgba(255, 255, 255, 0.10)" }
                    : {
                        color: "brand",
                        bg: isPad ? "transparent" : "black",
                      }
                }
                cursor="pointer"
              >
                <Center
                  h="48px"
                  w="100%"
                  color={isEarnPage ? "brand" : "white"}
                  fontWeight={"extrabold"}
                  justifyContent={isPad || isMobile ? "flex-start" : "center"}
                  fontFamily={"Rubik"}
                  fontSize={"xl"}
                >
                  Earn
                </Center>
              </Flex>
            </Link>
          </>
        </Box>
      )}

      {!isShowOnTop && (
        <Text
          fontWeight={"semibold"}
          fontSize={"lg"}
          cursor="pointer"
          boxSizing="border-box"
          borderBottomWidth={"2px"}
          borderBottomColor={"transparent"}
          _hover={{ borderBottom: borders.blackThick }}
          w={{ md: "unset", base: "100%" }}
          px={{ md: 2, base: 0 }}
          pl={6}
          borderTop={isMobile ? "1px solid #333" : "none"}
          borderBottom={isMobile ? "1px solid #333" : "none"}
          h={{ md: "unset", base: "48px" }}
          lineHeight={{ md: "unset", base: "48px" }}
          onClick={() =>
            window.open("https://fomome.gitbook.io/fomome", "_blank")
          }
        >
          Docs
        </Text>
      )}

      {(isMobile || !isShowOnTop) && (
        <Flex
          gap={{ md: 0, base: 10 }}
          alignItems={{ md: "center", base: "flex-start" }}
          w={{ md: "max-content", base: "100%" }}
          mt={{ md: 0, base: 2 }}
          pl={{ md: 0, base: "10px" }}
        >
          <Image
            src={
              isMobile
                ? twitterWhiteIcon
                : isTwitterHover
                ? twitterHoverIcon
                : twitterIcon
            }
            alt="twitter"
            cursor="pointer"
            onMouseOver={() => setIsTwitterHover(true)}
            onMouseLeave={() => setIsTwitterHover(false)}
            onClick={() => window.open(twitter, "_blank")}
          />
          <Image
            src={
              isMobile
                ? telegramwhiteIcon
                : isTelegramHover
                ? telegramHoverIcon
                : telegramIcon
            }
            alt="telegram"
            cursor="pointer"
            onMouseOver={() => setIsTelegramHover(true)}
            onMouseLeave={() => setIsTelegramHover(false)}
            onClick={() => window.open(tg, "_blank")}
          />
        </Flex>
      )}
    </Flex>
  );
};

export default Header;
