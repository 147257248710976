import { Text } from "@chakra-ui/react";

const EarnPage = () => {
  return (
    <>
      <Text color="white">earn page</Text>
    </>
  );
};

export default EarnPage;
