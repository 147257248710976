import { useReadContracts } from "wagmi";
import tokenPairAbi from "abis/token-pair-v3.json";
import tokenPair2Abi from "abis/bnb_usdt.json";
import { bigNumberify } from "./numbers";

const poolsContract = {
  why: {
    tokenNativeAddr: "0x6cd061334aafee6cdd5bc5c958e71f769d7ca8cc",
    usdtNativeAddr: "0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE",
  },
  tfb: {
    tokenNativeAddr: "0x427bf5b37357632377ecbec9de3626c71a5396c1",
    usdtNativeAddr: "0x1A26d803C2e796601794f8C5609549643832702C",
  },
};

export const useTokenPrice = (symbol) => {
  const { tokenNativeAddr, usdtNativeAddr } = poolsContract[symbol] || {};

  const { data } = useReadContracts({
    contracts: [
      {
        abi: tokenPairAbi,
        address: tokenNativeAddr,
        functionName: "slot0",
      },
      {
        abi: tokenPair2Abi,
        address: usdtNativeAddr,
        functionName: "latestAnswer",
      },
    ],
    query: {
      enabled: tokenNativeAddr && usdtNativeAddr,
    },
  });

  if (!tokenNativeAddr || !usdtNativeAddr) return 0;

  const ret1 = data?.[0].result?.[0] as bigint;
  const ret2 = data?.[1].result as bigint;

  const WHYPricePerBNB = ret1
    ? bigNumberify(ret1)
        ?.div(bigNumberify(2)!?.pow(bigNumberify(96)!))!
        .pow(bigNumberify(2)!)
    : bigNumberify(0);
  const bnbPrice = ret2
    ? bigNumberify(ret2)?.div(bigNumberify(Math.pow(10, 8))!)!
    : bigNumberify(0)!;

  const price = WHYPricePerBNB?.multipliedBy(bnbPrice)!;

  return price?.gt(bigNumberify(0)!) ? price.toNumber() : 0.00003242;
};
