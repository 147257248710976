import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { useState } from "react";

const CustomTabList = ({ tabs }) => {
  const [curtab, setCurTab] = useState(0);
  return (
    <Tabs>
      <Box w="100%" h={{ lg: 12, md: 12, base: 8 }} bg="black">
        <TabList border={"none"} h={"100%"}>
          {tabs.map(({ name }, index) => {
            const isActive = curtab === index;
            return (
              <Tab
                onClick={() => setCurTab(index)}
                cursor="pointer"
                bgColor={isActive ? "white" : "black"}
                border="none"
                color={isActive ? "black" : "white"}
                px={{ lg: 10, md: 10, base: 3 }}
                fontSize={{ lg: "lg", md: "lg", base: "xs" }}
                fontWeight={isActive ? "bold" : "medium"}
                h={"100%"}
                key={name}
              >
                {name}
              </Tab>
            );
          })}
        </TabList>
      </Box>
      <TabPanels>
        {tabs.map(({ panel, name }) => {
          return (
            <TabPanel
              pt={{ lg: 6, md: 6, base: "10px" }}
              pb={0}
              px={{ lg: 10, md: 10, base: "10px" }}
              key={name}
            >
              {panel}
            </TabPanel>
          );
        })}
      </TabPanels>
    </Tabs>
  );
};

export default CustomTabList;
