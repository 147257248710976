import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { borders } from "theme";

const CustomModal = ({ isOpen, onClose, title, children }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />

      <ModalContent
        border={borders.brandNormal}
        bg="black"
        maxW={{ lg: "unset", base: "95%" }}
        maxH={"90vh"}
        px={{ lg: 6, base: "10px" }}
        w="fit-content"
        borderRadius={0}
      >
        <ModalHeader
          borderBottom={borders.brandNormal}
          display={"flex"}
          justifyContent={"space-between"}
          h={"3.75rem"}
          px={0}
        >
          <Text
            fontSize={"xl"}
            fontFamily={"Rubik One"}
            fontWeight={"medium"}
            color="brand"
            textTransform={"uppercase"}
          >
            {title}
          </Text>
          <ModalCloseButton
            boxSize={6}
            pos="initial"
            color="brand"
            borderRadius={0}
            _hover={{
              bg: "brand",
              color: "black",
            }}
          />
        </ModalHeader>
        <ModalBody
          maxW={"39.5rem"}
          py={{ lg: 6, base: 3 }}
          px={0}
          overflowY={"scroll"}
          className="common-srollbar"
        >
          {children}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CustomModal;
