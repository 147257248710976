import {
  Box,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuList,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import arrowIcon from "assets/arrow.svg";
import Jazzicon, { jsNumberForAddress } from "react-jazzicon";
import { copyToClipboard, shortenAddress } from "utils/util";
import { useAccount, useDisconnect } from "wagmi";
import copyIcon from "assets/copy.svg";
import logoutIcon from "assets/logout.svg";
import { useIsMobileView } from "hooks/usePageSize";

const WalletDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = useIsMobileView();
  const { address } = useAccount();
  const toast = useToast();
  const { disconnect } = useDisconnect();
  const disconnectAccount = () => {
    disconnect();
  };

  return (
    <Box
      onMouseOver={() => setIsOpen(true)}
      onMouseOut={() => setIsOpen(false)}
    >
      <Menu autoSelect={false} isOpen={isOpen}>
        {({ isOpen }) => (
          <>
            <MenuButton
              px={6}
              fontWeight={"medium"}
              color={"white"}
              borderColor={"transparent"}
              borderWidth={"1px"}
              fontSize="lg"
              bg="black"
              h="3.75rem"
              w={{ lg: "unset", base: "142px" }}
            >
              <Flex
                gap={"10px"}
                h="100%"
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Jazzicon
                  paperStyles={{ borderRadius: "0px" }}
                  diameter={28}
                  seed={jsNumberForAddress(address as string)}
                />
                {!isMobile && <Text>{shortenAddress(address)}</Text>}
                <Image
                  boxSize={6}
                  src={arrowIcon}
                  transform={isOpen ? "rotateX(180deg)" : "none"}
                />
              </Flex>
            </MenuButton>

            <MenuList
              color="white"
              zIndex={101}
              bgColor={"black"}
              border="none"
              pl={4}
              pr={5}
              py={1}
              mr={2}
              borderRadius={"2px"}
              minWidth="0"
              fontSize="14px"
            >
              <Flex h={"3.75rem"} alignItems={"center"}>
                <Jazzicon
                  paperStyles={{ borderRadius: "2px" }}
                  diameter={40}
                  seed={jsNumberForAddress(address as string)}
                />
                <Box ml={2}>
                  <Text
                    color="white"
                    fontSize={"sm"}
                    fontWeight={"medium"}
                    mb="2px"
                  >
                    {shortenAddress(address)}
                  </Text>
                  <Text color="b05" fontSize={"xs"}>
                    {/* TODO */}
                    Metamask
                  </Text>
                </Box>

                <Box
                  cursor="pointer"
                  onClick={() => {
                    copyToClipboard(address as string);
                    toast({
                      title: `Address copied to your clipboard`,
                      status: "success",
                    });
                  }}
                  p={2}
                  ml={6}
                  _hover={{ bg: "rgba(255, 255, 255, 0.10)" }}
                  borderRadius={"2px"}
                >
                  <Image src={copyIcon} boxSize={6} />
                </Box>

                <Box w={"1px"} h={"18px"} bgColor={"b04"} mx={5} />

                <Box
                  cursor="pointer"
                  onClick={disconnectAccount}
                  p={2}
                  _hover={{ bg: "rgba(255, 255, 255, 0.10)" }}
                  borderRadius={"2px"}
                >
                  <Image src={logoutIcon} boxSize={6} />
                </Box>
              </Flex>
            </MenuList>
          </>
        )}
      </Menu>
    </Box>
  );
};

export default WalletDropdown;
