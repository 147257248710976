import { Text } from "@chakra-ui/react";
import { useRef } from "react";
import Countdown from "react-countdown";

// convert the timestamp to make it showing the same date with GMT8
export const changeDateForGMT8 = (timestamp) => {
  const curDate = new Date(timestamp);
  const timezoneOffset = curDate.getTimezoneOffset();
  const offsetDiff = -(-8 * 60 - timezoneOffset);
  const newTimestamp = timestamp + offsetDiff * 60 * 1000;

  return newTimestamp;
};

export const padZero = (num) => {
  if (num < 10) return `0${num}`;
  return num;
};

const CountdownRenderer = ({ hours, minutes, seconds, hasSpace, ...rest }) => {
  const space = hasSpace ? " " : "";
  return (
    // @ts-ignore
    <Text {...rest}>
      {padZero(hours)}
      {space}:{space}
      {padZero(minutes)}
      {space}:{space}
      {padZero(seconds)}
    </Text>
  );
};

const CustomCountdown = ({
  endDate,
  useGMT8 = true,
  hasSpace = true,
  ...rest
}) => {
  const endTime = useRef(endDate);
  if (endTime.current < Date.now()) return null;
  return (
    <Countdown
      date={endTime.current}
      renderer={(res) => (
        <CountdownRenderer hasSpace={false} {...res} {...rest} />
      )}
      now={() => {
        return useGMT8 ? changeDateForGMT8(Date.now()) : Date.now();
      }}
    />
  );
};

export default CustomCountdown;
