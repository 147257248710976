import { Box, Heading, Text, Image, Stack, Center } from "@chakra-ui/react";

function PageNotFound() {
  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      h="100%"
    >
      <Center textAlign={"center"} height={"100%"}>
        {/* <Image src={pageNotFoundImg} alt="Error 404" /> */}
        <Stack>
          <Heading
            as={"h1"}
            fontSize={{ base: "3rem", sm: "4.5rem" }}
            fontWeight={700}
            lineHeight={"110%"}
            letterSpacing={"-0.02em"}
            color={"theme.light"}
            pb={"1rem"}
          >
            Oops
          </Heading>
          <Heading
            as={"h2"}
            fontSize={{ base: "1.5rem", sm: "2rem" }}
            fontWeight={700}
            lineHeight={"110%"}
            letterSpacing={"-0.02em"}
            pb={"0.5rem"}
          >
            Something went wrong.
          </Heading>
          <Text
            fontSize={{ base: "0.875rem", sm: "1rem" }}
            color={"gray.light"}
            lineHeight={"180%"}
          >
            Error 404 Page Not Found
          </Text>
        </Stack>
      </Center>
    </Box>
  );
}

export default PageNotFound;
