import { useState } from "react";
import { useAccount, useWriteContract } from "wagmi";
import TeamModal from "./TeamModal";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import {
  Box,
  Button,
  Center,
  Flex,
  Icon,
  Image,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { borders } from "theme";
import { CustomInput } from "components/Input";
import tipsIcon from "assets/tips.svg";
import whyIcon from "assets/why.png";
import { TEAMS, getTeamInfo } from "config/team";
import { useIsMobileView } from "hooks/usePageSize";
import { getContract, ZERO_ADDRESS } from "config/contracts";
import fomomeContractAbi from "abis/abi-fomome.json";
import { parseEther, stringToHex } from "viem";
import TokenAbi from "abis/token.json";

const _getTeamInfo = (memeTokenInfo) => {
  return [
    {
      ...getTeamInfo(TEAMS.snake),
      desc: "Trickle down Divinomics.",
      valueText: "++ Most dividends",
    },
    {
      ...getTeamInfo(TEAMS.whale),
      desc: "Feed on the greed of others.",
      valueText: `++ Most ${memeTokenInfo?.symbol} to pot`,
    },
    {
      ...getTeamInfo(TEAMS.bull),
      desc: "Break upwards, never stagnate.",
      valueText: "Balanced distribution",
    },
    {
      ...getTeamInfo(TEAMS.bear),
      desc: "Stand alone, fight alone.",
      valueText: `Maximize ${memeTokenInfo?.symbol} to current round`,
    },
  ];
};

const TutorialTabPanel = ({
  chainId,
  inputVal,
  setInputVal,
  buyingTokenNum,
  tokenAllowance,
  setBuyingTokenNum,
  actualBuyingToken,
  memeTokenInfo,
  isDuringRound,
  isWaitingWinner,
  isDuringGapTime,
}) => {
  const KEYS = [1, 2, 5, 10, 100];
  const { isConnected } = useAccount();
  const isMobile = useIsMobileView();
  const [curTeamId, setCurTeamId] = useState<TEAMS>(TEAMS.snake);
  const [isFocus, setIsFocus] = useState(false);
  const [isOpenTeamModal, setIsOpenTeamModal] = useState(false);
  const toast = useToast();
  const { openConnectModal } = useConnectModal();
  const [isApproving, setIsApproving] = useState(false);
  const params = new URLSearchParams(window.location.search);
  const ref = params.get("ref");
  const refType = params.get("type") as string; // wallet / name / id
  const [isBuying, setIsBuying] = useState(false);

  const buyFuncMap = {
    wallet: "buyXaddr",
    id: "buyXid",
    name: "buyXname",
  };

  const isButtonActive = !!inputVal && curTeamId !== undefined && isConnected;

  const maxUint256 = BigInt(
    "115792089237316195423570985008687907853269984665640564039457584007913129639935"
  );

  const needApproval =
    buyingTokenNum &&
    (parseEther(buyingTokenNum) as BigInt) > (tokenAllowance as BigInt);

  const { writeContract: writeApproveContract } = useWriteContract({
    mutation: {
      onSettled: async (data, error) => {
        setIsApproving(false);
        if (error) {
          if (error.message.includes("reject")) {
            return toast({ title: "Wallet Rejection", status: "error" });
          }
          return toast({ title: error.message, status: "error" });
        }
        toast({
          title: `Successfully approved!`,
          status: "success",
        });
      },
    },
  });

  const fomomeContractInfo = {
    address: getContract(chainId, "fomome"),
    abi: fomomeContractAbi,
    chainId: chainId,
  };

  const { writeContract: buyContract } = useWriteContract({
    mutation: {
      onSettled: async (data, error) => {
        setIsBuying(false);
        if (error) {
          if (error.message.includes("reject")) {
            return toast({ title: "Wallet Rejection", status: "error" });
          }
          return toast({ title: error.message, status: "error" });
        }
        const isGotoVault =
          !isDuringGapTime && !isDuringRound && !isWaitingWinner;

        toast({
          title: isGotoVault
            ? "Thank you for activating the next round of the game, the token to buy keys has entered your Vault"
            : `Buy successfully!`,
          status: "success",
        });
        setInputVal("");
        setCurTeamId(TEAMS.snake);
        setBuyingTokenNum(0);
      },
    },
  });

  const handleClick = () => {
    if (!isConnected) return openConnectModal?.();

    if (!inputVal) {
      return toast({
        title: "Please buy key",
        status: "warning",
      });
    }

    if (needApproval) {
      setIsApproving(true);
      // @ts-ignore
      writeApproveContract({
        abi: TokenAbi,
        address: memeTokenInfo?.address,
        functionName: "approve",
        args: [getContract(chainId, "fomome"), maxUint256],
      });
      return;
    }

    if (curTeamId === undefined) {
      return toast({
        title: "Please select a team",
        status: "warning",
      });
    }

    if (curTeamId === undefined) {
      return toast({
        title: "Please select a team",
        status: "warning",
      });
    }

    if (isDuringGapTime) {
      return toast({
        title: "The Fomo Game has not yet started",
        status: "warning",
      });
    }

    if (!actualBuyingToken) {
      return toast({
        title: "Failed to retrieve the token quantity, please try again.",
        status: "warning",
      });
    }
    if (isBuying) return;
    setIsBuying(true);
    console.log({
      functionName: buyFuncMap[refType] || "buyXaddr",
      args: [
        refType === "name"
          ? stringToHex(ref || "", { size: 32 })
          : ref || ZERO_ADDRESS,
        curTeamId,
        actualBuyingToken,
      ],
    });
    buyContract({
      ...fomomeContractInfo,
      functionName: buyFuncMap[refType] || "buyXaddr",
      args: [
        refType === "name"
          ? stringToHex(ref || "", { size: 32 })
          : ref || ZERO_ADDRESS,
        curTeamId,
        actualBuyingToken,
      ],
    });
  };

  return (
    <>
      <VStack spacing={6} w="100%">
        <Box w="100%">
          <Center
            bg="black"
            h={"1.75rem"}
            fontSize={{ lg: "md", md: "md", base: "12px" }}
            color="white"
            px={{ lg: 6, md: 6, base: 3 }}
            w="max-content"
          >
            Buy A Key
          </Center>
          <Flex direction={"column"} gap={0} border={borders.blackNormal}>
            <Flex
              h={{ lg: "3.75rem", md: "3.75rem", base: 20 }}
              direction={{ lg: "row", md: "row", base: "column" }}
            >
              <Flex
                p="10px"
                w={{ lg: "55%", md: "55%", base: "100%" }}
                bgColor={"b10"}
                border={isFocus ? borders.blackThick : "none"}
                h={{ md: "100%", base: "50%" }}
              >
                <CustomInput
                  leftContent={
                    <Icon
                      boxSize={{ lg: 10, md: 10, base: 6 }}
                      viewBox="0 0 40 40"
                      fill="none"
                    >
                      <path
                        d="M22.1654 20.4997C22.9987 21.833 23.332 23.333 23.332 24.9997C23.332 29.6663 19.6654 33.333 14.9987 33.333C10.332 33.333 6.66536 29.6663 6.66536 24.9997C6.66536 20.333 10.332 16.6663 14.9987 16.6663C16.832 16.6663 18.332 17.1663 19.832 18.1663L23.6654 14.333L21.832 12.4997L24.1654 10.1663L25.9987 11.9997L27.1654 10.833L25.332 8.99968L27.6654 6.66634L31.832 10.833L22.1654 20.4997ZM14.9987 29.9997C17.832 29.9997 19.9987 27.833 19.9987 24.9997C19.9987 22.1663 17.832 19.9997 14.9987 19.9997C12.1654 19.9997 9.9987 22.1663 9.9987 24.9997C9.9987 27.833 12.332 29.9997 14.9987 29.9997Z"
                        fill="black"
                      />
                    </Icon>
                  }
                  h="100%"
                  borderRadius={"none"}
                  _focus={{ border: "none" }}
                  color="black"
                  fontSize={
                    inputVal.length > 7 ? "xl" : isMobile ? "24px" : "40px"
                  }
                  fontFamily={"Rubik Mono One"}
                  inputProps={{
                    value: inputVal,
                    onChange: (v) => {
                      setInputVal(v);
                    },
                  }}
                  onFocus={() => {
                    setIsFocus(true);
                  }}
                  onBlur={() => {
                    setIsFocus(false);
                  }}
                />
              </Flex>

              <Flex
                fontSize={{ lg: "20px", md: "20px", base: "md" }}
                fontWeight={"medium"}
                w={{ lg: "45%", md: "45%", base: "100%" }}
                h={{ md: "100%", base: "50%" }}
                alignItems={"center"}
                pl={{ lg: "18px", md: "18px", base: "10px" }}
                bgColor={"b09"}
              >
                <Text>@</Text>
                <Text
                  fontWeight={"bold"}
                  mr={1}
                  fontSize={
                    `${Number(buyingTokenNum || 0)}`.length > 10
                      ? "xs"
                      : isMobile
                      ? "md"
                      : "xl"
                  }
                >
                  {Number(buyingTokenNum || 0)}
                </Text>
                <Image src={whyIcon} boxSize={5} mr={1} />
                <Text>{memeTokenInfo?.symbol}</Text>
              </Flex>
            </Flex>
            <Flex>
              {KEYS.map((num) => {
                return (
                  <Center
                    flex="1"
                    h={10}
                    _hover={{ bg: "b10" }}
                    _active={{ bg: "b09" }}
                    cursor={"pointer"}
                    onClick={() => {
                      setInputVal(`${+inputVal + num}`);
                    }}
                    key={num}
                    fontSize={{ md: "md", base: "xs" }}
                  >
                    <Text>+{num} key</Text>
                  </Center>
                );
              })}
            </Flex>
          </Flex>
        </Box>
        <Box w="100%">
          <Center
            bg="black"
            h={"1.75rem"}
            fontSize={{ md: "md", base: "xs" }}
            color="white"
            px={6}
            w="max-content"
            gap={"10px"}
          >
            Choose A team
            <Image
              src={tipsIcon}
              cursor={"pointer"}
              onClick={() => {
                setIsOpenTeamModal(true);
              }}
              boxSize={4}
            />
          </Center>
          <Flex
            border={borders.blackNormal}
            direction={{ md: "row", base: "column" }}
          >
            {_getTeamInfo(memeTokenInfo).map(
              ({ name, desc, icon, valueText, id }) => {
                return (
                  <Flex
                    direction={{ md: "column", base: "row" }}
                    alignItems={"center"}
                    gap={{ md: 2, base: 4 }}
                    flex="1"
                    cursor="pointer"
                    bg={id === curTeamId ? "brand" : "#fff"}
                    _hover={{
                      bg: "brand",
                    }}
                    py={2}
                    px={3}
                    key={id}
                    zIndex={2}
                    onClick={() => {
                      setCurTeamId(id as TEAMS);
                    }}
                  >
                    <Flex
                      direction={{ md: "column", base: "row" }}
                      gap={{ md: 0, base: 4 }}
                    >
                      <Image
                        src={icon}
                        alt={name}
                        boxSize={{ md: 10, base: 8 }}
                      />
                      <Text
                        fontSize={{ md: "lg", base: "sm" }}
                        fontWeight={"bold"}
                        lineHeight={"31px"}
                        textAlign={"center"}
                        w={{ md: "unset", base: "50px" }}
                      >
                        {name}
                      </Text>
                    </Flex>
                    <VStack
                      gap={0}
                      alignItems={"flex-start"}
                      flex={{ base: 1 }}
                    >
                      <Text
                        fontWeight={"medium"}
                        fontSize={{ md: "sm", base: "xs" }}
                        maxW="100%"
                        textAlign={"center"}
                      >
                        {desc}
                      </Text>
                      <Text
                        color="green0"
                        fontSize={{ md: "sm", base: "xs" }}
                        fontWeight={"medium"}
                        textAlign={"center"}
                      >
                        {valueText}
                      </Text>
                    </VStack>
                  </Flex>
                );
              }
            )}
          </Flex>
        </Box>
        <Button
          variant={isButtonActive && !isApproving ? "normal" : "plain"}
          h={{ md: 16, base: 10 }}
          w="100%"
          alignItems={"center"}
          onClick={handleClick}
          isDisabled={isApproving}
        >
          <Text fontSize={{ md: "2xl", base: "md" }} fontWeight={"bold"}>
            {isApproving
              ? "Approving..."
              : needApproval
              ? `Approve ${buyingTokenNum} ${memeTokenInfo?.symbol}`
              : `Send ${memeTokenInfo?.symbol} To Buy`}
          </Text>
          <Icon
            width={{ md: "28px", base: "20px" }}
            height={{ md: "28px", base: "20px" }}
            viewBox="0 0 28 28"
            fill="none"
          >
            <mask
              id="mask0_10124_381"
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="28"
              height="28"
            >
              <rect width="28" height="28" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_10124_381)">
              <path
                d="M7.75599 21.9631L5.16016 19.3673L15.6893 8.83809H6.61849V5.16309H21.9602V20.5048H18.2852V11.4339L7.75599 21.9631Z"
                fill="currentColor"
              />
            </g>
          </Icon>
        </Button>
      </VStack>
      <TeamModal
        isOpen={isOpenTeamModal}
        onClose={() => setIsOpenTeamModal(false)}
      />
    </>
  );
};

export default TutorialTabPanel;
