import { bsc, bscTestnet } from "viem/chains";

export const MAIN_TOKEN = {
  [bscTestnet.id]: [
    {
      address: "0xe6ADF425D11cEe361F2B61D9612134D85b8A775d",
      symbol: "TFB",
      nativeTokenAddr: "0xee786a1aa32fc164cca9a28f763fbc835e748129",
      nativeTokenDecimal: 8,
    },
  ],
  [bsc.id]: [
    {
      address: "0x9ec02756a559700d8d9e79ece56809f7bcc5dc27",
      symbol: "WHY",
      nativeTokenAddr: "0x242a1ff6ee06f2131b7924cacb74c7f9e3a5edc9",
      nativeTokenDecimal: 18,
    },
  ],
};

export const getMemeTokenInfo = (chainId, token = "WHY") => {
  const tokenList = MAIN_TOKEN[chainId] || MAIN_TOKEN[bsc.id];
  return tokenList.find(
    ({ symbol }) => symbol.toLowerCase() === token.toLowerCase()
  );
};
