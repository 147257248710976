import { bsc, bscTestnet } from "viem/chains";

const CONTRACTS = {
  [bscTestnet.id]: {
    fomome: "0x149205ff015FA6Db3c7b212c3cddb88ceCBeA817",
    playBook: "0xA34Df981E0D75b6d690e917bAc4B54430c8dCEba",
  },
  [bsc.id]: {
    fomome: "0x149205ff015FA6Db3c7b212c3cddb88ceCBeA817",
    playBook: "0xA34Df981E0D75b6d690e917bAc4B54430c8dCEba",
  },
};

export function getContract(chainId: number, name: string): `0x${string}` {
  if (!CONTRACTS[chainId]) {
    throw new Error(`Unknown chainId ${chainId}`);
  }

  if (CONTRACTS[chainId][name] === undefined) {
    throw new Error(`Unknown contract "${name}" for chainId ${chainId}`);
  }

  return CONTRACTS[chainId][name];
}

export const DEFAULT_CHAIN_ID = bscTestnet.id;

export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";
