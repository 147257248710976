import { Text } from "@chakra-ui/react";
import CustomModal from "components/CustomModal";

const HowToPlayModal = ({ isOpen, onClose, memeTokenInfo }) => {
  return (
    <CustomModal isOpen={isOpen} onClose={onClose} title="how to play">
      <Text fontSize={"xs"} color="white">
        Buy a key by choosing the amount of keys you want, choosing the team and
        then clicking on the Send {memeTokenInfo?.symbol} to Buy button.
      </Text>
      <Text fontSize={"xs"} color="white" mt={6}>
        Congratulations, you are now holding the key to the pot! You will win
        the pot as long as nobody else buys another key.
      </Text>
      <Text
        color="brand"
        fontSize={"md"}
        fontWeight={"medium"}
        textTransform={"capitalize"}
        textAlign={"left"}
        w="100%"
        mt={6}
      >
        Want to spread your joy and earn 10% affiliate fees?
      </Text>
      <Text color="b07" fontSize={"xs"} mt={2}>
        Just register a name for{" "}
        <Text as="span" color="brand">
          0.01 {memeTokenInfo?.symbol}
        </Text>{" "}
        in the Referrals tab. Once your name is registered, a Vanity Referral
        link will be made for you. For example{" "}
        <Text as="span" color="white" textDecorationLine={"underline"}>
          fomome.me?ref=inventor.
        </Text>
        <br />
        <br /> Whenever someone purchases keys through your link, 10% of their
        purchase will go directly to you! Btw, if you have a name registered and
        you are the most recent key buyer, then your name will show up at the
        top! For example, satoshi is EXIT SCAMMING.
      </Text>
    </CustomModal>
  );
};

export default HowToPlayModal;
