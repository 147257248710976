import {
  Box,
  Center,
  Flex,
  Image,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import bgIcon from "assets/bg.svg";
import whyIcon from "assets/why.png";
import starIcon from "assets/wide-star.png";
import { borders } from "theme";
import spinIcon from "assets/spinIcon.png";
import CustomTabList from "components/CustomTabList";
import PurchaseTabPanel from "./PurchasePanel";
import RoundTabPanel from "./RoundPanel";
import WinModal from "./WinModal";
import { useEffect, useState } from "react";
import {
  useAccount,
  useChainId,
  useReadContract,
  useReadContracts,
  useWriteContract,
} from "wagmi";
import NewRoundModal from "./NewRoundModal";
import CustomCountdown, { changeDateForGMT8 } from "components/CustomCountdown";
import { TEAM_INFO, TEAMS } from "config/team";
import VaultPanel from "./VaultPanel";
import RefferalsPanel from "./RefferalsPanel";
import { useIsMobileView } from "hooks/usePageSize";
import { getMemeTokenInfo } from "config/tokens";
import { getContract } from "config/contracts";
import fomomeContractAbi from "abis/abi-fomome.json";
import { formatAmount } from "utils/numbers";
import BigNumber from "bignumber.js";
import { parseEther } from "viem";
import TokenAbi from "abis/token.json";
import { getCurLocalWinnerList, hexToString, shortenAddress } from "utils/util";
import HowToPlayModal from "./HowToPlay";
import moneyIcon from "assets/money.png";
import rocketIcon from "assets/rocket.png";
import eyeIcon from "assets/eye.svg";
import { useTokenPrice } from "utils/price";

const GamePage = () => {
  const [isOpenHowToPlayModal, setIsOpenHowToPlayModal] = useState(false);
  const [isOpenWinModal, setIsOpenWinModal] = useState(false);
  const [isOpenNewRoundModal, setIsOpenNewRoundModal] = useState(false);
  const isMobile = useIsMobileView();
  const { address } = useAccount();
  const account = address?.toLowerCase();
  const chainId = useChainId();
  const [inputVal, setInputVal] = useState("");
  const toast = useToast();
  const params = new URLSearchParams(window.location.search);
  const token = params.get("token") || "";
  const memeTokenInfo = getMemeTokenInfo(chainId, token);
  const tokenPrice = useTokenPrice(token);
  const roundIntervalTime = 120 * 1000;
  const roundIntervalTime4Round1 = 480 * 1000;
  const [isDuringRound, setIsDuringRound] = useState(false);
  const [isDuringGapTime, setIsDuringGapTime] = useState(false);
  const [isWaitingWinner, setIsWaitingWinner] = useState(false);

  const fomomeContractInfo = {
    address: getContract(chainId, "fomome"),
    abi: fomomeContractAbi,
    chainId: chainId,
  };

  const { data } = useReadContracts({
    contracts: [
      {
        ...fomomeContractInfo,
        functionName: "getPlayerInfoByAddress",
        args: [account],
      },
      {
        ...fomomeContractInfo,
        functionName: "getCurrentRoundInfo",
      },
      {
        abi: TokenAbi,
        address: memeTokenInfo?.address,
        functionName: "allowance",
        args: [address, getContract(chainId, "fomome")],
        chainId: chainId,
      },
    ],
    query: {
      refetchInterval: 2000,
    },
  });

  const { data: tokenPerKey } = useReadContract({
    ...fomomeContractInfo,
    functionName: "iWantXKeys",
    args: [parseEther(`${+inputVal}`)],
  });

  const [data1, data2, data3] = data || [];
  const playerInfo = data1?.result as any[];
  const curRoundInfo = data2?.result as any[];
  const tokenAllowance = data3?.result;

  const [buyingTokenNum, setBuyingTokenNum] = useState<string | number>(0);

  useEffect(() => {
    if (!inputVal) return setBuyingTokenNum(0);
    if (!tokenPerKey) return;
    setBuyingTokenNum(
      tokenPerKey ? formatAmount(tokenPerKey as BigNumber, 18, 6) : 0
    );
    return () => {};
  }, [tokenPerKey, inputVal]);

  const [
    id,
    name,
    keysOwned,
    winingsVault,
    generalVault,
    affiliateVault,
    roundEth,
  ] = playerInfo || [];

  const [
    ethInvested,
    roundId,
    totalKeys,
    roundEndTime,
    roundStartTime,
    curPot,
    leadsPlayerCurMixId,
    leadsPlayerAddr,
    leadsPlayerName,
    whalesEth,
    bearsEth,
    snakesEth,
    bullsEth,
    airdropPot,
  ] = curRoundInfo || [];

  const curRoundNum = Number(roundId || 1);
  const lastRoundNum = Math.max(1, curRoundNum - 1);
  const registeredName = !name || name == 0 ? "" : hexToString(name);

  const { data: roundData } = useReadContracts({
    contracts: [
      {
        ...fomomeContractInfo,
        functionName: "round_",
        args: [lastRoundNum],
      },
      {
        ...fomomeContractInfo,
        functionName: "round_",
        args: [curRoundNum],
      },
    ],
  });
  const lastRoundInfo = roundData?.[0].result;
  const curRoundDetailInfo = roundData?.[1].result;

  const { data: lastWinnerInfo } = useReadContract({
    ...fomomeContractInfo,
    functionName: "plyr_",
    args: [lastRoundInfo?.[0]],
  });

  useEffect(() => {
    if (lastWinnerInfo?.[0]) {
      let curList = getCurLocalWinnerList();
      if (!curList.includes(lastWinnerInfo?.[0])) {
        curList.push(lastWinnerInfo?.[0]);
      }
      localStorage.setItem("WINNER_LIST", JSON.stringify(curList));
    }
  }, [lastWinnerInfo]);

  const winnerInfo = {
    address: lastWinnerInfo?.[0],
    id: lastRoundInfo?.[0],
    teamId: Number(lastRoundInfo?.[1]),
    winningNum: ((lastRoundInfo?.[7] || BigInt(0)) * BigInt(48)) / BigInt(100),
  };

  const teamPoolInfo = {
    [TEAMS.snake]: snakesEth,
    [TEAMS.whale]: whalesEth,
    [TEAMS.bull]: bullsEth,
    [TEAMS.bear]: bearsEth,
  };

  const vaultInfo = {
    atExit: winingsVault,
    referrals: affiliateVault,
    dividends: generalVault,
    total:
      (generalVault || BigInt(0)) +
      (affiliateVault || BigInt(0)) +
      (winingsVault || BigInt(0)),
  };

  useEffect(() => {
    try {
      const data = localStorage.getItem("FOMOME_ROUND_INFO_SHOWN");
      if (!curRoundNum || curRoundNum <= 1 || !isDuringGapTime) {
        return;
      }
      if (data) {
        const _data = JSON.parse(data);
        if (_data.curRoundNum !== curRoundNum) {
          setIsOpenWinModal(true);
        }
      } else {
        setIsOpenWinModal(true);
      }
    } catch (err) {}
  }, [curRoundNum, isDuringGapTime]);

  const { writeContract: withdrawContract } = useWriteContract({
    mutation: {
      onSettled: async (data, error) => {
        if (error) {
          if (error.message.includes("reject")) {
            return toast({ title: "Wallet Rejection", status: "error" });
          }
          return toast({ title: error.message, status: "error" });
        }
        toast({
          title: `Successfully Withdraw!`,
          status: "success",
        });
      },
    },
  });

  const onWithdraw = () => {
    withdrawContract({
      ...fomomeContractInfo,
      functionName: "withdraw",
    });
  };

  const curRoundStartTime = Number(roundStartTime) * 1000;
  const curRoundEndTime = Number(roundEndTime) * 1000;

  useEffect(() => {
    function setTime() {
      const now = changeDateForGMT8(Date.now());
      const _isDuringGapTime =
        now > curRoundStartTime && now <= curRoundStartTime + roundIntervalTime;
      setIsDuringRound(
        now > curRoundStartTime + roundIntervalTime && now <= curRoundEndTime
      );
      setIsDuringGapTime(_isDuringGapTime);

      setIsWaitingWinner(!curRoundDetailInfo?.[0] && now > curRoundEndTime);
    }
    setTime();
    const timer = setInterval(setTime, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [roundStartTime, roundEndTime, curRoundDetailInfo]);

  const endTime = isDuringRound
    ? curRoundEndTime
    : curRoundStartTime +
      (curRoundNum <= 1 ? roundIntervalTime4Round1 : roundIntervalTime);

  const CommonCountDown = ({ ...rest }) => {
    if (!isDuringRound && !isDuringGapTime) return null;
    return (
      <CustomCountdown
        fontSize={{ md: "2xl", base: "xl" }}
        fontWeight={"medium"}
        fontFamily="DM Mono"
        endDate={endTime}
        {...rest}
      />
    );
  };

  const countdownPrefix = isDuringRound ? (
    <Flex>
      <VStack spacing={1} alignItems={"flex-start"}>
        <Text
          fontSize={{ md: "2xl" }}
          fontFamily={"Rubik"}
          fontWeight={"extrabold"}
        >
          Hurry! Jackpot drains in
        </Text>
        <CommonCountDown />
      </VStack>
    </Flex>
  ) : isDuringGapTime ? (
    <Flex gap={3}>
      <Image src={rocketIcon} boxSize={{ md: 8, base: 6 }} mt={2} />
      <VStack spacing={1} alignItems={"flex-start"}>
        <Text
          fontSize={{ md: "2xl" }}
          fontFamily={"Rubik"}
          fontWeight={"extrabold"}
        >
          Next round starts in
        </Text>
        <CommonCountDown endTime={curRoundStartTime + roundIntervalTime} />
      </VStack>
    </Flex>
  ) : (
    <Text
      fontSize={{ md: "20px", base: "md" }}
      fontFamily={"Rubik"}
      fontWeight={"bold"}
    >
      {isWaitingWinner ? (
        <>
          Be the first to win big! <br /> Unlock up to{" "}
          <Text
            as="span"
            fontWeight={900}
            fontSize={{ md: "24px", base: "20px" }}
          >
            10x
          </Text>{" "}
          returns with your keys now!
        </>
      ) : (
        <>
          Claim your rewards or buy keys to
          <br /> kick off the next round!
        </>
      )}
    </Text>
  );

  if (!token || !memeTokenInfo)
    return (
      <Center w="100%" flex="1">
        <Text fontSize={"xl"} fontWeight={"bold"} color="white">
          Token {token} is not found!
        </Text>
      </Center>
    );

  return (
    <>
      <Flex w="100%" flexDirection="column" h="100%" flex="1">
        <Box
          w="100%"
          h={{ md: "22rem", base: "16.5rem" }}
          bg="brand"
          bgImage={isMobile ? "" : bgIcon}
          bgRepeat={"no-repeat"}
          bgPos="right 63px top 29px"
        ></Box>
        <Box
          pos="absolute"
          w="100%"
          px={{ lg: 10, md: "22px", base: "10px" }}
          py={{ md: 4, base: "10px" }}
        >
          <Box maxW={"1360px"} mx="auto" pos="relative">
            {!isMobile && (
              <Box
                boxSize={{ lg: "14rem", md: "14rem", base: "7rem" }}
                pos="absolute"
                top={{ lg: "4.5rem", md: "4.5rem", base: "7rem" }}
                right={{ lg: "-32px", md: "-32px", base: "-14px" }}
                className="spin-logo"
                zIndex={2}
              >
                <Image
                  boxSize={{ lg: "14rem", md: "14rem", base: "7rem" }}
                  src={spinIcon}
                />
              </Box>
            )}
            <Flex
              gap={{ lg: 4, md: "22px", base: "10px" }}
              alignItems={"center"}
              flexWrap={"wrap"}
            >
              <Text
                fontFamily={"Rubik One"}
                fontSize={{ lg: "46px", md: "36px", base: "24px" }}
                textTransform="uppercase"
              >
                {winnerInfo.address ? (
                  <Flex gap="6px" alignItems={"center"}>
                    <Image src={eyeIcon} boxSize={{ md: 8, base: "27px" }} />{" "}
                    <Text
                      fontSize={{ md: "40px", base: "24px" }}
                      fontStyle={"italic"}
                      fontWeight={"medium"}
                      textDecoration={"underline"}
                      fontFamily={"Rubik"}
                    >
                      {shortenAddress(winnerInfo.address)}
                    </Text>{" "}
                    <Image src={eyeIcon} boxSize={{ md: 8, base: "27px" }} />
                  </Flex>
                ) : (
                  "SOMEONE ELSE"
                )}{" "}
              </Text>
              <Flex
                gap={{ lg: 4, md: "22px", base: "10px" }}
                alignItems={"center"}
              >
                <Text
                  as="span"
                  fontFamily={"Rubik One"}
                  fontSize={{ lg: "46px", md: "36px", base: "24px" }}
                  textTransform="uppercase"
                >
                  IS PUMPING MEME
                </Text>
                <Text
                  px={{ md: 5, base: 3 }}
                  borderRadius={"full"}
                  fontWeight={"bold"}
                  fontSize={{ md: "xl", base: "sm" }}
                  cursor="pointer"
                  py={{ md: "5px", base: "2px" }}
                  color="white"
                  bg="black"
                  boxSizing="border-box"
                  _hover={{ textDecoration: "underline" }}
                  onClick={() => setIsOpenHowToPlayModal(true)}
                  h="fit-content"
                >
                  Tutorial
                </Text>
              </Flex>
            </Flex>
            <Flex alignItems={"center"} gap={2}>
              {!isMobile && (
                <Text
                  fontFamily={"Rubik"}
                  fontSize={{ md: "28px" }}
                  fontWeight={"bold"}
                >
                  The only way to lose is to stop playing!
                </Text>
              )}
            </Flex>
            {isMobile && (
              <Text
                fontFamily={"Rubik"}
                fontSize={"sm"}
                fontWeight={"semibold"}
              >
                The only way to lose is to stop playing!
              </Text>
            )}
            <Flex
              gap={8}
              pt={{ md: 3, base: "6px" }}
              alignItems={{ lg: "center", base: "flex-start" }}
              maxW={{ lg: "calc(100% - 200px)", base: "unset" }}
              flexWrap={"wrap"}
              rowGap={{ lg: 2, md: 5, base: 4 }}
              flexDirection={{ lg: "row", base: "column" }}
            >
              <Flex
                pos="relative"
                gap={2}
                bg="babyBlue"
                boxShadow={"5px 4px 0px 0px #000"}
                fontFamily={"Roboto"}
                fontSize={{ lg: "32px", md: "24px", base: "sm" }}
                fontWeight={"medium"}
                h={{ lg: 16, md: 14, base: 8 }}
                py={2}
                px={{ lg: 4, base: 2 }}
                border={borders.blackNormal}
                alignItems={"center"}
              >
                {!isMobile && (
                  <Image
                    src={starIcon}
                    pos="absolute"
                    top={{ lg: "-24px", md: -5 }}
                    left={{ lg: "-24px", md: -5 }}
                    boxSize={{ lg: 12, md: 10 }}
                  />
                )}
                <Text whiteSpace={"nowrap"}>
                  {curPot
                    ? formatAmount(curPot as BigNumber, 18, 4, 0, true)
                    : 0}{" "}
                  {memeTokenInfo?.symbol}
                </Text>
                <Image src={whyIcon} boxSize={{ lg: 12, md: 8, base: 6 }} />
                <Text whiteSpace={"nowrap"}>
                  ≈{" "}
                  {(
                    ((curPot
                      ? formatAmount(curPot as BigNumber, 18, 6, 0, true)
                      : 0) as number) * tokenPrice
                  ).toFixed(2)}{" "}
                  USD
                </Text>
              </Flex>
              {!curRoundEndTime ? null : isDuringGapTime || isDuringRound ? (
                <Flex gap={2} alignItems={"center"}>
                  {countdownPrefix}
                </Flex>
              ) : (
                <Flex gap={3}>
                  <Image src={moneyIcon} boxSize={{ md: 8, base: 6 }} />
                  <Text
                    fontFamily={"Rubik"}
                    fontSize={"2xl"}
                    fontWeight={"extrabold"}
                  >
                    {countdownPrefix}
                  </Text>
                </Flex>
              )}
            </Flex>
            <Flex
              gap={10}
              mt={{ md: "20px", base: 4 }}
              direction={{ lg: "row", md: "column", base: "column" }}
              justifyContent={"center"}
            >
              <Box
                bg="white"
                border={borders.blackNormal}
                flex="1"
                pb={{ md: 10, base: 4 }}
                minH={{ lg: "556px" }}
                maxW={{ lg: "660px" }}
              >
                <CustomTabList
                  tabs={[
                    {
                      name: "Purchase",
                      panel: (
                        <PurchaseTabPanel
                          chainId={chainId}
                          inputVal={inputVal}
                          setInputVal={setInputVal}
                          buyingTokenNum={buyingTokenNum}
                          tokenAllowance={tokenAllowance}
                          setBuyingTokenNum={setBuyingTokenNum}
                          actualBuyingToken={tokenPerKey}
                          memeTokenInfo={memeTokenInfo}
                          isDuringRound={isDuringRound}
                          isWaitingWinner={isWaitingWinner}
                          isDuringGapTime={isDuringGapTime}
                        />
                      ),
                    },
                    {
                      name: "Vault",
                      panel: (
                        <VaultPanel
                          memeTokenInfo={memeTokenInfo}
                          tokenPrice={tokenPrice}
                          vaultInfo={vaultInfo}
                          onWithdraw={onWithdraw}
                        />
                      ),
                    },
                    {
                      name: "Referrals",
                      panel: (
                        <RefferalsPanel
                          address={account}
                          chainId={chainId}
                          name={registeredName}
                          id={id}
                          memeTokenInfo={memeTokenInfo}
                        />
                      ),
                    },
                  ]}
                />
              </Box>
              <Box
                bg="white"
                border={borders.blackNormal}
                flex="1"
                pb={{ md: 10, base: 4 }}
                pos="relative"
                maxW={{ lg: "660px" }}
              >
                <CustomTabList
                  tabs={[
                    {
                      name: "Round",
                      panel: (
                        <RoundTabPanel
                          isDuringRound={isDuringRound}
                          isDuringGapTime={isDuringGapTime}
                          CommonCountdown={CommonCountDown}
                          curRoundNum={curRoundNum}
                          curPot={curPot}
                          keysOwned={keysOwned}
                          winingsVault={winingsVault}
                          generalVault={generalVault}
                          affiliateVault={affiliateVault}
                          tokenPrice={tokenPrice}
                          memeTokenInfo={memeTokenInfo}
                          roundTotalKeys={totalKeys}
                          isWaitingWinner={isWaitingWinner}
                        />
                      ),
                    },
                    {
                      name: "Team",
                      panel: (
                        <Flex
                          px={{ md: "3.125rem", base: 0 }}
                          flexWrap={"wrap"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          h="100%"
                          w="100%"
                        >
                          {Object.keys(teamPoolInfo).map((teamId) => {
                            const value = teamPoolInfo[teamId];
                            const { name, icon, potPercent } =
                              TEAM_INFO[teamId];
                            return (
                              <VStack
                                py={{ md: 6, base: 3 }}
                                spacing={2}
                                w="50%"
                                key={teamId}
                              >
                                <Image
                                  src={icon}
                                  boxSize={{ md: "5rem", base: 10 }}
                                />
                                <Text
                                  pt={2}
                                  fontSize={{ md: "2xl", base: "xl" }}
                                  fontWeight={"bold"}
                                >
                                  {name}
                                </Text>
                                <Flex
                                  fontSize={{ md: "16px", base: "sm" }}
                                  gap="4px"
                                >
                                  <Text fontWeight={"bold"}>
                                    {(formatAmount(
                                      value,
                                      18,
                                      4,
                                      0,
                                      true
                                    ) as number) * potPercent}
                                  </Text>{" "}
                                  <Text fontWeight={"medium"}>
                                    {memeTokenInfo?.symbol}
                                  </Text>
                                </Flex>
                              </VStack>
                            );
                          })}
                        </Flex>
                      ),
                    },
                  ]}
                />
              </Box>
            </Flex>
          </Box>
        </Box>
      </Flex>
      <WinModal
        isOpen={isOpenWinModal}
        onClose={() => {
          setIsOpenWinModal(false);
          setIsOpenNewRoundModal(true);
        }}
        address={account}
        curRoundNum={curRoundNum}
        winnerInfo={winnerInfo}
        tokenPrice={tokenPrice}
        vaultInfo={vaultInfo}
        keysOwned={keysOwned}
        onWithdraw={onWithdraw}
        memeTokenInfo={memeTokenInfo}
      />
      <NewRoundModal
        isOpen={isOpenNewRoundModal}
        onClose={() => setIsOpenNewRoundModal(false)}
        curRoundNum={curRoundNum}
        memeTokenInfo={memeTokenInfo}
        curPot={curPot}
      />
      <HowToPlayModal
        isOpen={isOpenHowToPlayModal}
        onClose={() => setIsOpenHowToPlayModal(false)}
        memeTokenInfo={memeTokenInfo}
      />
    </>
  );
};

export default GamePage;
