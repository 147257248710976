import { Box, Flex, Image, Text } from "@chakra-ui/react";
import starIcon from "assets/star.png";
import { getCurLocalWinnerList, shortenAddress } from "utils/util";
import Marquee from "react-fast-marquee";
import { useEffect, useState } from "react";

const TopScrollBanner = () => {
  const [showList, setShowList] = useState<string[]>([]);

  useEffect(() => {
    const timer = setInterval(() => {
      const list = getCurLocalWinnerList();
      let _showList = list;

      if (list.length <= 0) {
        _showList = Array(10).fill("Someone else");
      } else if (list.length < 3) {
        _showList = list.concat(list).concat(list);
      } else if (list.length < 6) {
        _showList = list.concat(list);
      }

      setShowList(_showList);
    }, 2000);

    return () => clearInterval(timer);
  }, []);

  return (
    <Box w="100vw" overflow={"hidden"} h={{ lg: 10, base: 6 }}>
      <Marquee speed={100}>
        <Flex
          gap={4}
          bg="black"
          alignItems={"center"}
          h={{ lg: 10, base: 6 }}
          w="max-content"
        >
          {showList.map((address, index) => {
            return (
              <Flex
                gap={4}
                alignItems={"center"}
                key={index}
                minW={{ lg: "343px", md: "243px" }}
              >
                <Image src={starIcon} boxSize={{ lg: "25px", base: 4 }} />
                <Flex
                  gap={1}
                  fontSize={{ lg: "md", base: "xs" }}
                  fontWeight={"bold"}
                  letterSpacing={"1.6px"}
                  color="brand"
                >
                  <Text fontWeight={"normal"} color="white">
                    {address.startsWith("0x")
                      ? shortenAddress(address as `0x${string}`)
                      : address}
                  </Text>
                  <Text>Purchased Keys</Text>
                  {/* <Text>{keyNum.toFixed(0)}</Text>
                  <Text>{keyNum > 1 ? "Keys" : "Key"}</Text> */}
                </Flex>
              </Flex>
            );
          })}
        </Flex>
      </Marquee>
    </Box>
  );
};

export default TopScrollBanner;
