import { Button } from "@chakra-ui/react";
import { useConnectModal } from "@rainbow-me/rainbowkit";

const ConnectWalletButton = ({ ...rest }) => {
  const { openConnectModal } = useConnectModal();
  return (
    <Button
      variant={"normal"}
      onClick={openConnectModal}
      h={"60px"}
      {...rest}
      borderRadius={0}
    >
      Connect Wallet
    </Button>
  );
};

export default ConnectWalletButton;
